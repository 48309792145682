import React, { useEffect } from 'react'
import styled from 'styled-components'
import img from '../images/Ellicott-Hero.jpg'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import 'aos/dist/aos.css'

const Container = styled.div`
  background-image: url(${img});
  display: flex;
  width: 120em;
  height: 80vh;
  flex-direction: column;
  padding-top: 5%;
  opacity: 1;
`

const Title = styled.div`
  margin: auto;
  color: white;
  font-size: 2.5em;
  text-shadow: 0px 3px 6px #00000029;
  letter-spacing: -1.43px;
  text-align: center;
  font-family: 'CentraNo2-Bold';
`

const CirlcDownArrow = styled.div`
  background: #1c1c1c 0% 0% no-repeat padding-box;
  color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
`

export const Hero: React.FC = () => {
let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <FlexDiv
      vert
      style={{
        width: '100%',
        minHeight: '60vh',
        paddingTop: '1.5%',
        opacity: '1',
        backgroundImage: 'url(' + img + ')',
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
      }}
      className="heroContainer"
    >
      <Title>
        <Link to="/properties" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'inherit'}}>
        <h1 className="heroTitle"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
          <Link to="/properties" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'inherit'}}> I am looking to buy </Link>
          </h1>
        </Link>
        <Link to="/advantage" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'inherit'}}>
        <h1 className="heroTitle"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
          <Link to="/advantage" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'inherit'}}>I am looking to sell</Link>
          </h1>
          </Link>
      </Title>
      {/* <CirlcDownArrow className="heroDownArrow">
        <FontAwesomeIcon style={{ padding: 15 }} icon={faLongArrowAltDown} />
      </CirlcDownArrow> */}
    </FlexDiv>
  )
}
