import React, { useEffect } from 'react'
import { FlexDiv } from 'design-system/FlexDiv'
import { Input } from '../design-system/Input'
import logo from '../images/startYourSearch.jpg'
import Searchfor from '../images/search-for.png'
import SearchforMobile from '../images/Looking-to-Buy-(Home-Page).png'
import styled from 'styled-components'
import searchIcon from '../assets/icons/search.svg'
import { Icon } from '../design-system/Icon'
import { Link } from 'gatsby'
import { GeocoderInput, GeocoderLocation } from './GeocodeInput'
import AOS from "aos"
import "aos/dist/aos.css"

const IconDiv = styled.button`
  height: 100%;
  width: 10%;
  font-family: CentraNo2-Book;
  display: flex;

  background: white;
  border: 1px solid gray;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const StartSearchTitle = styled.h1`
  font-family: 'CentraNo2-Bold';
  font-size: 3em;
  letter-spacing: 1px;
`

const SearchForm = styled.form`
  width: 90%;
  display: flex;
`

export const StartYourSearch: React.FC<{
  onSearch: (loc: GeocoderLocation) => void
}> = props => {
  const [searchLocation, setSearchLocation] = React.useState<GeocoderLocation>()

  const handleSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchLocation && props.onSearch) {
      props.onSearch({
        position: searchLocation.position,
        bounds: searchLocation.bounds,
        label: searchLocation.label,
      })
    }
  }

let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <FlexDiv className="lookToBuySec homePage"
               data-aos="fade-up"
               data-aos-delay="50"
               data-aos-duration="2000">
      <FlexDiv className="lookingToBuy">
        
        <Link to="/properties" style={{ textDecoration: 'none', color: 'inherit' }}>
        <h1 style={{ paddingLeft: '100px', background: '#fff', marginTop: '0px !important', paddingBottom: '1.5em' }} className="homeLookingBuy">
          Looking to Buy a Property?
        </h1>
        </Link>
      <FlexDiv 
          style={{
            flex: 1,
          }}
          className="SearchWrapOne"
        >
        <FlexDiv className="SearchWrapTwo">
          <FlexDiv className="searchWrapper" style={{
        background: '#000',
          color: 'white',
      }}>
        <p style={{ marginRight: '0px !important'}} className="LookingtoBuyP">
          Search for available listings by property type, price, location, and number of bathrooms and bedrooms.
        </p>
        <p style={{ marginRight: '0px !important'}} className="LookingtoBuyP">
          Whether you're seeking a detached home, a townhouse or condo, our property search feature provides you access to over 500,000 properties within the Greater Toronto Area and beyond. Let us help you find your next home.
        </p>
        </FlexDiv>
        <FlexDiv style={{ flex: 1, width: '50%', background: '#000', color: '#fff'}} className="searchPageMock">
              <Link to="/properties" style={{ display: 'contents' }}>
                <img src={Searchfor} className="searchFor desktopOnly" />
                <img src={SearchforMobile} className="searchFor mobileSearch mobileOnly"/>
              </Link>
        </FlexDiv>
        </FlexDiv>
        </FlexDiv>
        </FlexDiv>
 </FlexDiv>
  )
}
