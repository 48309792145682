import React, { useEffect } from "react"
import styled from "styled-components"
import { FlexDiv } from "../design-system/FlexDiv"
import { Link } from "gatsby"
import img from "../images/Toronto.jpg"
import img2 from "../images/vaughanCenter2.jpg"
import img3 from "../images/markham2.jpg"
import img4 from "../images/mimicoHero.jpg"
import img5 from "../images/northYorkHero.jpg"
import img6 from "../images/downtown-aurora.jpg"
import img7 from "../images/stCaths.jpg"
import img8 from "../images/newmarket2.jpg"
import img9 from "../images/stouffville.jpg"
import img10 from "../images/king-city.jpg"
import img11 from "../images/pickering.jpg"
import img12 from "../images/brampton.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import dev2 from "../images/dev-2.jpg"
import dev3 from "../images/dev-3.jpg"
import dev4 from "../images/4th-dev.jpg"
import "aos/dist/aos.css"

const CardTitle = styled.p`
  font-family: CentraNo2-Bold;
  color: #fff;
  margin-bottom: 0.25em;
  -webkit-font-smoothing: antialiased;
  font-size: 1em !important;
  text-align: center;
`

const TitleH1 = styled.h1`
  font-family: "Montserrat-Bold";
  font-size: 2.1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #1c1c1c;
  margin: 2em 0 1.5em;
`

const SubTitle = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 500;
  font-size: 1.1em;
  margin: 0 3em 3em;
`

const DivWrapper = styled.div`
  padding: 2em 0 3em;
`

const FeaturedTitle = styled.h3`
  font-family: CentraNo2-Medium;
  font-size: 2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const FeaturedTitle2 = styled.h3`
  font-family: CentraNo2-Medium;
  font-size: 2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const FeaturedSubtitle = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 100;
  font-size: 0.8em;
`

const FeaturedSubtitle2 = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 100;
  font-size: 0.8em;
  max-width: 70%;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 25%;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-size: 0.8em;
`

export const DiscoverCommunities: React.FunctionComponent = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <DivWrapper
      className="discoverCommunities"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="2000"
    >
      <FlexDiv justify="center" className="discoverCommunitiesTitle">
        <FlexDiv vert style={{ textAlign: "" }}>
          <TitleH1 className="propTitle propTopSpacing">
            Available Properties <br className="mobileOnlyOD" /> by Community:
          </TitleH1>
        </FlexDiv>
      </FlexDiv>
      <FlexDiv className="propCommunityCards">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          interval={7000}
          className="desktopOnly"
        >
          <div className="communityCardDivWrapper">
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/toronto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/toronto"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Toronto</h1>
                  </Link>
                  <Link
                    to="/toronto"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Toronto, the capital of the province of Ontario, is a
                      major Canadian city along Lake Ontario’s northwestern
                      shore...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/vaughan"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img2}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/vaughan"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Vaughan</h1>
                  </Link>
                  <Link
                    to="/vaughan"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Vaughan is a city in Ontario, Canada. It is located in the
                      Regional Municipality of York, just north of Toronto...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/etobicoke"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img4}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/etobicoke"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Etobicoke</h1>
                  </Link>
                  <Link
                    to="/etobicoke"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Suburban Etobicoke is home to several lakefront parks,
                      golf courses, and vast Centennial Park with a conservatory
                      featuring...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/markham"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img3}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/markham"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Markham</h1>
                  </Link>
                  <Link
                    to="/markham"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Markham is a city in the Regional Municipality of York in
                      Southern Ontario, Canada. It is approximately 30 km
                      northeast of Downtown...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </div>
          <div className="communityCardDivWrapper">
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/aurora"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img6}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/aurora"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Aurora</h1>
                  </Link>
                  <Link
                    to="/aurora"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is a town in central York Region in the Greater Toronto
                      Area, within the Golden Horseshoe of Southern Ontario,
                      Canada. It is located...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/newmarket"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img8}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/newmarket"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1 style={{ maxWidth: "100%" }}> Newmarket</h1>
                  </Link>
                  <Link
                    to="/newmarket"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is a town and regional seat of the Regional Municipality
                      of York in the Canadian province of Ontario. It is part of
                      Greater Toronto...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/stouffville"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img9}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/stouffville"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Stouffville</h1>
                  </Link>
                  <Link
                    to="/stouffville"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is the primary urban area within the town of
                      Whitchurch-Stouffville in the York Region of Ontario,
                      Canada. It is situated within the...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/kingcity"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img10}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/kingcity"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1 style={{ maxWidth: "100%" }}> King City</h1>
                  </Link>
                  <Link
                    to="/kingcity"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is an unincorporated Canadian community in King, Ontario
                      located north of Toronto. It is the largest community in
                      King Township...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </div>
          <div className="communityCardDivWrapper">
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/pickering"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img11}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/pickering"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Pickering</h1>
                  </Link>
                  <Link
                    to="/pickering"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is a city located in Southern Ontario, Canada, immediately
                      east of Toronto in Durham Region. For centuries, it fell
                      under the stewardship...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/stcaths"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img7}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/stcaths"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1 style={{ maxWidth: "100%" }}> St. Catharines</h1>
                  </Link>
                  <Link
                    to="/stcaths"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is the largest city in Canada's Niagara Region and the
                      sixth largest urban area in the province of Ontario. As of
                      2016...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/northyork"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img5}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/northyork"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1 style={{ maxWidth: "100%" }}> North York</h1>
                  </Link>
                  <Link
                    to="/northyork"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      Is one of the six administrative districts of Toronto,
                      Ontario, Canada. It is located directly north of York, Old
                      Toronto and East York...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <Link
                  to="/brampton"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    src={img12}
                    style={{
                      width: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                    }}
                    className="PropCardImg"
                  />
                </Link>
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <Link
                    to="/brampton"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <h1> Brampton</h1>
                  </Link>
                  <Link
                    to="/brampton"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <p>
                      The York Region District School Board operates 27 public
                      elementary schools in Richmond Hill, as well as...
                    </p>
                  </Link>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </div>
        </Carousel>
      </FlexDiv>
  <Carousel
        showArrows={true}
        infiniteLoop={true}
        interval={7000}
        autoPlay={true}
        stopOnHover={true}
        className="mobileOnlyOD newDevsCarousel"
      >
        <div>
          <Link
            to="/toronto"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Toronto</h1>
                    <p>
                      Toronto, the capital of the province of Ontario, is a major Canadian 
                      city along Lake Ontario’s northwestern shore...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/vaughan"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img2} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Vaughan</h1>
                    <p>
                      Vaughan is a city in Ontario, Canada. It is located in the Regional Municipality of York, 
                      just north of Toronto...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
         <div>
          <Link
            to="/etobicoke"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img4} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Etobicoke</h1>
                    <p>
                      Suburban Etobicoke is home to several lakefront parks, golf courses, and vast Centennial
                      Park with a conservatory featuring...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
         <div>
          <Link
            to="/markham"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img3} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Markham</h1>
                    <p>
                      Markham is a city in the Regional Municipality of York in Southern Ontario, Canada.
                      It is approximately 30 km northeast of Downtown...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/aurora"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img6} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Aurora</h1>
                    <p>
                      Is a town in central York Region in the Greater Toronto Area,
                      within the Golden Horseshoe of Southern Ontario, Canada. It is located...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/newmarket"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img8} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Newmarket</h1>
                    <p>
                      Is a town and regional seat of the Regional Municipality 
                      of York in the Canadian province of Ontario. It is part of Greater Toronto...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/stouffville"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img9} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Stouffville</h1>
                    <p>
                      Is the primary urban area within the town of Whitchurch-Stouffville in 
                      the York Region of Ontario, Canada. It is situated within the...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/kingcity"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img10} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> King City</h1>
                    <p>
                      Is an unincorporated Canadian community in King, Ontario located north of Toronto. 
                      It is the largest community in King Township...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/pickering"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img11} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Pickering</h1>
                    <p>
                      Is a city located in Southern Ontario, Canada, immediately east of Toronto in Durham Region. 
                      For centuries, it fell under the stewardship...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/stcaths"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img7} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> St. Catharines</h1>
                    <p>
                      Is the largest city in Canada's Niagara Region and the sixth largest urban area in the province of Ontario. As of 2016...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/northyork"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img5} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> North York</h1>
                    <p>
                      Is one of the six administrative districts of Toronto, Ontario, Canada. It is located directly north of York, Old Toronto and East York...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/brampton"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={img12} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Brampton</h1>
                    <p>
                      The York Region District School Board operates 27 public elementary schools in Richmond Hill, as well as...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
      </Carousel>
    
      <FlexDiv justify="center" className="newDevTitleWrapper">
        <FlexDiv vert style={{ textAlign: "" }}>
          <TitleH1 className="newDevs newDevMobileTitle" style={{ marginTop: "0px" }}>
            Search for <br className="mobileOnly" />
            New Developments:
          </TitleH1>
        </FlexDiv>
      </FlexDiv>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        interval={7000}
        className="desktopOnly NewDevsBottom"
      >
        <div className="communityCardDivWrapper">
          <Link
            to="/lawrence-collection"
            style={{
              color: "inherit",
              textDecoration: "none",
              display: "contents",
            }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={dev2} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Lawrence Collection</h1>
                    <p>
                      Currently in construction at 241 Lawrence Avenue in
                      Richmond Hill, the Lawrence...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
          <FlexDiv className="communityCards">
            <FlexDiv style={{ width: "" }} className="communityCardWrapper">
              <Link
                to="/222-richmond-park"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={dev3}
                  style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
                  className="PropCardImg"
                />
              </Link>
              <FlexDiv
                style={{ background: "#000", color: "#fff" }}
                className="propertyCommWrapper"
              >
                <Link
                  to="/222-richmond-park"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <h1 style={{ maxWidth: "100%" }}> 222 Richmond Park</h1>
                </Link>
                <Link
                  to="/222-richmond-park"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <p>
                    222 Richmond is a new single-family flagship development by
                    Mackenzie Hall Homes...
                  </p>
                </Link>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv className="communityCards">
            <FlexDiv style={{ width: "" }} className="communityCardWrapper">
              <Link
                to="/184-richmond-street"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={dev4}
                  style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
                  className="PropCardImg"
                />
              </Link>
              <FlexDiv
                style={{ background: "#000", color: "#fff" }}
                className="propertyCommWrapper"
              >
                <Link
                  to="/184-richmond-street"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <h1 style={{ maxWidth: "100%" }}>184 Richmond Street</h1>
                </Link>
                <Link
                  to="/184-richmond-street"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <p>
                    184 Richmond is a new single-family creation by Mackenzie
                    Hall Homes, currently in...
                  </p>
                </Link>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </div>
        <div className="communityCardDivWrapper">
          <FlexDiv className="communityCards">
            <FlexDiv
              style={{ width: "23%", marginLeft: "1%" }}
              className="communityCardWrapper"
            >
              <Link
                to="/155-rumble-street"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <img
                  src={dev4}
                  style={{
                    width: "100%",
                    maxHeight: 200,
                    objectFit: "cover",
                  }}
                  className="PropCardImg"
                />
              </Link>
              <FlexDiv
                style={{ background: "#000", color: "#fff" }}
                className="propertyCommWrapper"
              >
                <Link
                  to="/155-rumble-street"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <h1 style={{ maxWidth: "100%" }}> 155 Rumble Street</h1>
                </Link>
                <Link
                  to="/toronto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <p>
                    155 Rumble is a pristine single-family development by
                    Mackenzie Hall Homes currently...
                  </p>
                </Link>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </div>
      </Carousel>

      <Carousel
        showArrows={true}
        infiniteLoop={true}
        interval={7000}
        autoPlay={true}
        stopOnHover={true}
        className="mobileOnlyOD newDevsCarousel"
      >
        <div>
          <Link
            to="/lawrence-collection"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={dev2} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> Lawrence Collection </h1>
                    <p>
                      Currently in construction at 241 Lawrence Avenue in Richmond Hill, the Lawrence...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/lawrence-collection"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={dev3} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> 222 Richmond Park </h1>
                    <p>
                      222 Richmond is a new single-family flagship development by Mackenzie Hall Homes...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
         <div>
          <Link
            to="/lawrence-collection"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={dev4} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> 184 Richmond Street </h1>
                    <p>
                      184 Richmond is a new single-family creation by Mackenzie Hall Homes, currently in...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>
        <div>
          <Link
            to="/lawrence-collection"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FlexDiv className="communityCards">
              <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                <img src={dev4} style={{ width: "100%" }} />
                <FlexDiv
                  style={{ background: "#000", color: "#fff" }}
                  className="propertyCommWrapper"
                >
                  <FlexDiv>
                    <h1> 155 Rumble Street </h1>
                    <p>
                      155 Rumble is a pristine single-family development by Mackenzie Hall Homes currently...
                    </p>
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Link>
        </div>

        
      </Carousel>
    </DivWrapper>
  )
}
