import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import "aos/dist/aos.css"
import Modal from 'react-modal'
import closeIcon from '../assets/icons/close-icon.svg'

const TitleH1 = styled.h1`
  font-family: 'Montserrat-ExtraBold';
  font-size: 37.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #1c1c1c;
  margin-bottom: 0.25em;
  -webkit-font-smoothing: antialiased;
`

const TitleP = styled.p`
  font-family: CentraNo2-Light;
  font-size: 1.25em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.36px;
  text-align: center;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
`

const TitlesNo = styled.h1`
  font-family: CentraNo2-Medium;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.26;
  letter-spacing: -1.2px;
  color: #1c1c1c;
  text-align: center;
  -webkit-font-smoothing: antialiased;
`

const TitlesNoPropType = styled.h1`
  font-family: CentraNo2-Medium;
  font-size: 1.75em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.26;
  letter-spacing: -1.2px;
  color: #1c1c1c;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  margin: 1em;
`

const MySpan = styled.span`
  display: flex;
  font-family: CentraNo2-Book;
  font-size: 1.25em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: -0.36px;
  color: #1c1c1c;
  float: right;
  -webkit-font-smoothing: antialiased;
`

const MySpan2 = styled.span`
  display: flex;
  float: right;
  font-family: CentraNo2-Book;
  font-size: 1.25em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.36px;
  top: 0;
  right: 0;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
`

const TitleH4 = styled.h4`
  font-family: CentraNo2-Book;
  font-size: 1.5em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: -0.36px;
  text-align: center;
  color: #1c1c1c;
  margin: 0 0 0.5em;
  -webkit-font-smoothing: antialiased;
`

const TitleH42 = styled.h4`
  font-family: CentraNo2-Book;
  font-size: 1.5em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.36px;
  text-align: center;
  color: #1c1c1c;
  -webkit-font-smoothing: antialiased;
`

const UnderP = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.875em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.21px;
  text-align: center;
  color: #1c1c1c;
  margin: 0.5em;
  -webkit-font-smoothing: antialiased;
`

const LeftArrow = styled.div`
  margin-top: 4%;
  svg {
    height: 3em;
    width: 2em !important;
  }
`

const RightArrow = styled.div`
  margin-top: 4%;
  svg {
    height: 3em;
    width: 2em !important;
  }
`

export const MonthlyMarketUpdates = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  type ModalIsOpen = `closed` | `opened`
  const [subscribeMonthlyModalisOpen, setsubscribeMonthlyModalisOpen] = useState<ModalIsOpen>(false)
  const handleModal = (s: ModalIsOpen) => {
    switch (s) {
      case `closed`:
        setsubscribeMonthlyModalisOpen(false)

        break
      case `opened`:
        setsubscribeMonthlyModalisOpen(true)
      default:
        break
    }
  }

  return (
    <FlexDiv
      vert
      container
      style={{
        width: '100%',
        overflow: 'hidden',
        backgroundColor: '#f5f5f5',
        marginTop: 25
      }}
      className="monthlyUpdate"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="2000"
    >
      <FlexDiv vert>
        <TitleH1>Monthly Market Update</TitleH1>
      </FlexDiv>
      <Carousel showArrows={true} infiniteLoop={true}>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            August 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>5,627</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>34</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,079,500</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to July 2021 - $1,070,201
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            August 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$1,648,298 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$1,313,839</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span>$1,127,429 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span>$942,6284</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$913,410</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span>$897,140 </span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$736,940</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$656,339</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            July 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>4,912</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>29</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,074,754</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to July 2021 - $1,061,724
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            July 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,515,763</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,320,269</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,268,630</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $963,994</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>  $963,545</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $884,533</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $744,092</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$659,820</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            June 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>6,474</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>15</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,146,254</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to June 2021 - $1,088,991
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            June 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,737,012</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,361,862</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,343,378</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $987,009</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>  $1,027,050</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $958,558</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $771,267</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$692,598</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            May 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>7,283</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>12</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,212,806</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to May 2021 - $1,108,124
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            May 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,914,890</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,432,951</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,426,273</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,044,443 </span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>  $1,045,874</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $958,558</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $793,124</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $722,778 </span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            April 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>8,008</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>11</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,254,436</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to April 2021 - $1,090,414
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            April 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span> $1,947,975</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span> $1,526,791</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,494,640</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,110,142</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,086,986</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $997,416</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $820,835</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $722,601</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            March 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>10,955</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>8</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,299,894</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to March 2021 - $1,097,351
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            March 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span> $1,920,018</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span> $1,632,832</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,545,447</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,201,097</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,117,469</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,079,798</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $831,351</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $760,410</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>


        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            February 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>9,097</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>9</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,334,544</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to Feb 2021 - $1,044,957
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            February 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span> $2,073,989</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span> $1,727,963</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,499,489</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,282,386</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,131,809</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,119,026</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $822,090</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $756,146</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            January 2022 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>5,636</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>13</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,242,793</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to January 2021 - $966,068
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            January 2022 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span> $1,886,413</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span> $1,702,143</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType> Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,471,535</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,236,081</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,080,284</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $1,083,801</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $760,643</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $720,532</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>



        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            December 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>6,031</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>14</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,157,849</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to December 2020 - $932,297
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            December 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,698,178</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,568,583</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,342,429 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,109,424</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$997,208</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $994,010</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $730,792</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $667,269</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            November 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>9,017</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>13</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,163,323</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to November 2020 - $955,889
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            November 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,807,983</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,492,821</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,431,988 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,070,530</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$981,759</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $955,010</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $745,951</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $646,211</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            October 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>9,783</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>13</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,155,345</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to October 2020 - $968,535
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            October 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,784,979</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,459,803</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,322,229 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $1,029,347</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$1,025,257</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $932,815</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $739,647</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $633,951</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>




        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            September 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>9,046</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>14</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,136,280</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to September 2020 - $960,613
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            September 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,778,928</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,451,471</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,304,504 </span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $991,073</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$930,056</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $902,853</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $744,730</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $634,111</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            August 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>8,596</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>16</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,070,911</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to August 2020 - $951,219
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            August 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,674,641</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,365,973</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,214,624</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $951,629</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $905,520</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $865,196</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $720,832</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $618,997</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>



        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            July 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>9,390</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>15</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,062,256</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to July 2020 - $943,594
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            July 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,633,649</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,346,186</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,205,814</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $944,062</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $$893,347</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $837,906</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $715,977</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $589,582</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>




        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            June 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>11,106</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>13</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,089,536</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to June 2020 - $931,131
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            June 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,699,881</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,329,873</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,267,044</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $914,926</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $941,474</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $836,851</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $717,466</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $611,610</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            May 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>11,951</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>11</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,108,453</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to May 2020 - $863,599
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            May 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,716,272</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,331,176</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,326,153</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $915,479</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $945,893</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $841,962</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $716,976</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $603,555</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>


        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            April 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>13,663</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>10</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,090,992</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to April 2020 - $821,392
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            April 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,699,756</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,308,185</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,308,799</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $925,938</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $942,371</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $831,152</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $727,137</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $612,341</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            March 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>15,652</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>10</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,097,565</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to March 2020 - $902,680
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            March 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,750,518</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,320,570</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span> $1,288,005</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span> $932,215</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $960,894</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $846,401</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $707,835</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $607,220</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            February 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>10,970</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>14</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$1,045,488</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to February 2020 - $910,290
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            February 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong> <span>$1,684,073</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong> <span>$1,300,853</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong>416:</strong>
                    <span>$1,324,244</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong>905:</strong>
                    <span>$932,551</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$913,037</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$845,518</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span>$676,837</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span>$563,587</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            January 2021 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>6,928</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>24</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$967,885</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to January 2020 - $838,087
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            January 2021 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,581,400</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,308,393</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,204,857</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $898,810</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $814,396</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $800,339</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $624,886</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $547,488</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>






        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            December 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>7,180</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>24</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$932,222</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to December 2019 - $838,662
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            December 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,475,758</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,175,753</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,160,108</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $829,437</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $842,902</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $746,130</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $625,828</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $540,472</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            November 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>8,766</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>19</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$955,615</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to November 2019 - $843,307
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            November 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,477,226</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,124,286</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,160,911</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $816,367</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $819,752</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $729,557</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $640,208</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $533,984</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            October 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>10,563</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>17</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$968,318</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to October 2019 - $851,877
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            October 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,470,857</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,123,886</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,154,087</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $796,557</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $828,090</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $725,079</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $668,161</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $541,582</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            September 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>11,083</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>16</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$960,772</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to September 2019 - $842,421
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            September 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,487,122</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,104,866</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,145,559</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $791,526</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $868,005</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $716,286</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $686,191</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $537,354</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%', marginBottom: 50 }} className="initialMonthlyTitle">
            August 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>10,775</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>17</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$951,404</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to August 2019 - $792,134
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            August 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,505,100</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,088,559</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,166,226</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $784,951</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $834,222</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $719,667</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $673,174</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $540,491</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            July 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>11,081</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>25</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$943,710</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to July 2019 - $806,971
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            July 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,541,003</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,060,318</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,181,014</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $773,513</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $852,284</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $701,853</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $682,999</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $527,117</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            June 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>8,701</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>26</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$930,869</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to June 2019 - $831,882
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            June 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,523,770</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,027,634</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,287,832</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $752,808</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $855,339</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $691,021</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $672,465</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $528,028</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            May 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>4,606</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>32</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$863,599</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to May 2019 - $838,248
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            May 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,422,273</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$942,668</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,143,322</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $716,549</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $794,626</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $659,704</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $674,028</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $527,117</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            April 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>2,975</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>19</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$821,392</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">*Compared to April - $820,373</UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            April 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,249,730</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$907,843</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,096,437</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $719,311</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $784,899</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $655,028</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $612,300</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $489,654</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            March 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>8,012</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>17</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$902,680</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to March 2019 - $788,133
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            March 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,465,826</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,005,999</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,155,457</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $772,796</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $834,201</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $690,848</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $712,746</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $535,189</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            February 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>7,256</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>26</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$910,290</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to Feb 2019 - $779,791
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            February 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,485,304</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$1,017,573</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,208,073</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $743,602</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $846,465</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $702,077</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $722,675</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $534,688</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>
        </div>

        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            January 2020 Real Estate Numbers
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            {/* <LeftArrow>
              <FontAwesomeIcon icon={faAngleLeft} />
            </LeftArrow> */}
            <div style={{ flex: 1 }}>
              <div>
                <TitlesNo>4,581</TitlesNo>
                <TitleH4>Properties Sold</TitleH4>
              </div>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan>+12.3%</MySpan>
          */}
            </div>
            <div style={{ flex: 1 }} className="avgMarket">
              <TitlesNo>37</TitlesNo>
              <TitleH42>
                Average Days <br /> on Market
              </TitleH42>
            </div>
            <div style={{ flex: 1 }}>
              <TitlesNo>$839,363</TitlesNo>
              {/* Hiding Percentage until we fix it as per flexbox spec 
          <MySpan2>+14.5%</MySpan2>
          */}
              <TitleH4>Average Home Sale Price*</TitleH4>
              <UnderP className="underP">
                *Compared to Jan 2019 - $747,175
              </UnderP>
            </div>
            {/* <RightArrow>
              <FontAwesomeIcon icon={faAngleRight} />
            </RightArrow> */}
          </FlexDiv>
        </div>
        <div>
          <TitleP style={{ marginTop: '0%' }} className="realEstateMonth">
            {' '}
            January 2020 Average Sold Price
          </TitleP>
          <FlexDiv justify="space-evenly" className="monthlyMobileStack">
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong> <span>$1,369,848</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong> <span>$957,287</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Semi-Detached</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $1,004,208</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $718,167</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
            <div className="monthlyTwoDivs">
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Townhome</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $782,274</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $667,974</span>{' '}
                  </TitleH4>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <TitlesNoPropType>Condo</TitlesNoPropType>
                  <TitleH4>
                    <strong> 416:</strong>
                    <span> $679,182</span>{' '}
                  </TitleH4>
                  <TitleH4>
                    <strong> 905:</strong>
                    <span> $521,878</span>{' '}
                  </TitleH4>
                </div>
              </div>
            </div>
          </FlexDiv>

        </div>
      </Carousel>
      <div style={{ textAlign: 'center', margin: '-25px 0 20px' }}>
        <a className="subscribeMonthlyNewsletter" href="#" style={{ textDecoration: 'none', color: 'inherit', fontSize: '18px', fontFamily: 'Montserrat-Light' }} onClick={() => handleModal('opened')}>
          Subscribe for Monthly Updates <span style={{ fontSize: '35px', transform: 'translateY(2.5px)', marginLeft: '10px', fontFamily: 'inherit', display: 'inline-flex' }}> &#8250; </span>
        </a>

        <Modal isOpen={subscribeMonthlyModalisOpen}
          onRequestClose={() => handleModal('closed')}
          className="monthlyModal"
        >
          <FlexDiv>
            <FlexDiv
              style={{
                flex: 50
              }}
              className="monthlyModalContainer"
            >
              <h1> Housing Market Update</h1>
              <p>Leave your details below for the latest resale data and price trends in Canada's housing market. Be well informed to make better decisions to buy or sell. </p>

              <form
                name="contact"
                method="post"
                action="/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                // onSubmit={this.handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field"
                    // onChange={this.handleChange}
                    />
                  </label>
                </div>
                <div className="field"
                  style={{ display: 'flex', width: '100%' }}>
                  <label className="label" htmlFor={'name'} style={{ display: 'none' }}>
                    Your name
                  </label>
                  <div className="control"
                    style={{ display: 'flex', width: '100%', marginTop: 10 }}>
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      placeholder={'Full Name'}
                      // onChange={this.handleChange}
                      id={'name'}
                      required={true}
                      style={{ height: 40, width: '100%', paddingLeft: 15, border: '1px solid #000', borderRadius: '3.5px' }}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={'email'} style={{ display: 'none' }}>
                    Email
                  </label>
                  <div className="control"
                    style={{ display: 'flex', width: '100%', marginTop: 10, }}>
                    <input
                      className="input"
                      type={'email'}
                      placeholder={'Email Address'}
                      name={'email'}
                      // onChange={this.handleChange}
                      id={'Email Address'}
                      required={true}
                      style={{ height: 40, width: '100%', paddingLeft: 15, border: '1px solid #000', borderRadius: '3.5px' }}
                    />
                  </div>
                </div>
                {/* <div className="field">
                  <label className="label" htmlFor={'city'}>
                    City
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'city'}
                      id={'city'}
                      required={true}
                    />
                  </div>
                </div> */}
                {/* <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      // onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div> */}


                <div className="field" style={{ textAlign: 'left' }}>
                  <button className="button is-link" type="submit">
                    Submit
                  </button>
                </div>
              </form>

            </FlexDiv>
            <FlexDiv className="monthlyModalCloseWrapper">
              <button
                onClick={() => handleModal('closed')}
                className="closeBtnImgWrapper">
                <img src={closeIcon} />
              </button>
            </FlexDiv>
          </FlexDiv>
        </Modal>
      </div>
    </FlexDiv>
  )
}
