import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import { LoginComponentProps } from 'components/LoginComponent'
import { SignupComponentProps } from 'components/SignUp'
import propDetails from '../images/Listing-Details.jpg'
import propDetailsMobile from '../images/Looking-to-Sell-(for-Home-Page).png'
import { services } from '@services'
import "aos/dist/aos.css"


const Links = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.3px;
  color: #ffffff;
  @media (max-width: 1515px) {
    font-size: 15px;
  }
  @media (max-width: 1360px) {
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1100px) {
    font-size: 11.5px;
  }
`

const PreffedSignup = styled.button`
  color: #fff;
  border: 1.5px solid #fff;
  padding: 15px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-size: 0.7em;
  width: 100%;
  background: transparent;
  margin-top: 2em;
  font-weight: 600;
`

const StyledCard = styled.div`
    height:300px
    background: gray
`

const DivWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2em 0 5em;
`

const CardTitle = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  margin: 1em 2em;
`

const CardTitle2 = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 2em 0 0.5em;
`

const CardSubTitle = styled.p`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const IconRightHack = styled.div`
  align-self: flex-end;
  margin: 1em;
`

const SubText = styled.p`
  font-family: 'CentraNo2-Book';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const InputFieldTemp = styled.div`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #fff;
  width: 100%;
  padding: 10px;
`

const SignUpBtn = styled.div`
  text-align: center;
  font-family: CentraNo2-Book;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  padding: 10px;
  font-size: 0.9em;
`

const FeaturedTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const PreferredSubText = styled.p`
  font-family: CentraNo2-Book;
  font-weight: 100;
  font-size: 0.8em;
`

const IconImg = styled.img`
  height: 2em;
`

export const PreferredClientFeatures: React.FunctionComponent<HeaderPropsType> = props => {
  const [loginIsOpen, setLoginIsOpen] = React.useState(false)
  const [signupIsOpen, setSignupIsOpen] = React.useState(false)

  const openLogin = () => {
    setLoginIsOpen(true)
  }

  const closeLogin = () => {
    setLoginIsOpen(false)
  }

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }

  const loginUser: LoginComponentProps['handleLoginUser'] = ({
    credentials,
    rememberMe,
  }) => {
    services.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(closeLogin)
  }
  const signupUser: SignupComponentProps['handleSignupUser'] = formData => {
    services.firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
    services.lifted.sendRegisterForm(formData)
  }

  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })

  return (
    <FlexDiv className="lookingtoSellWrapper"
               data-aos="fade-up"
               data-aos-delay="50"
               data-aos-duration="2000"
    >
      <FlexDiv className="lookingtoSellInnerWrapper">
        <FlexDiv className="propDetailsFlex homePropDeetz">
          <Link to="/advantage" style={{ display: 'contents'}}>
            <img src={propDetails} className="propDetailsImgSell desktopOnly" style={{ margin: '-12.5em auto 0' }} />
            <img src={propDetailsMobile} className="propDetailsImgSell mobileOnly" style={{ margin: '-12.5em auto 0' }} />
        </Link>
      </FlexDiv>
      <FlexDiv style={{ background: '#000', placeContent: 'flex-start' }} className="propDetailsTextWrapper homePageSec">
          <Link to="/advantage" style={{ textDecoration: 'none', color: 'inherit'}}>
            <h2 className="propDetailsTitle"> Looking to Sell your Home?</h2>
        </Link>
        <FlexDiv className="propDetailsText">
        <p style={{ color: '#fff'}}>
          At Ellicott Realty, we know that our success is tied to your complete satisfaction. Not only do want to receive top dollar for your listing, but also create a memorable experience for you and your buyer.
        </p>
        <p style={{ color: '#fff'}}>
            We understand the importance of finer detail, impeccable service and exceptional marketing when representing a new listing. These are key hallmarks that have helped us build a prestigious reputation in the real estate industry.
        </p>
          </FlexDiv>
</FlexDiv>
        </FlexDiv>
  </FlexDiv>
  )
}
