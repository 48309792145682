import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { Hero } from 'components/HeroSection'
import { MonthlyMarketUpdates } from 'components/MonthlyMarketUpdates'
import { EllicottListings } from 'components/EllicottListings'
import { DiscoverCommunities } from 'components/DiscoverCommunities'
import { PreferredClientFeatures } from 'components/PreferredClientFeatures'
import { CommunitySearch } from 'components/CommunitySearch'
import { NewDevelopments } from 'components/NewDevelopments'
import { Contact } from 'components/Contact'
import { StartYourSearch } from 'components/StartYourSearch'
import { navigate } from '@reach/router'
import { Helmet } from "react-helmet"
import LogRocket from 'logrocket';
LogRocket.init('lnan1y/ellicott');

const IndexPage: React.FunctionComponent = () => {
  const [startYourSearchString, setStartYourSearchString] = React.useState<
    string
  >()

  const handleStartSearch = (searchString: string) => {
    setStartYourSearchString(searchString)
    navigate(`/properties/?search=${searchString}`)
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <meta name="description" content="At Ellicott Realty, we deliver white glove experience that is both trustworthy and genuine. We constantly work to exceed and redefine even the highest expectations." />
        <title>Ellicott Realty</title>
      </Helmet>
      <EllicotLayout>
        <Hero />
        <MonthlyMarketUpdates />
        {/* <EllicottListings /> */}
        <StartYourSearch
          onSearch={location => handleStartSearch(JSON.stringify(location))}
        />
        <DiscoverCommunities />
        <PreferredClientFeatures />
        {/* <CommunitySearch /> */}
        {/* <NewDevelopments /> */}
        <Contact />
      </EllicotLayout>
    </div>
  )
}

export default IndexPage
